<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de transporte"
          icon="fas fa-dolly"
          btn_name="transporte"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'lg'"
          :button_new="true"
          :actions="true"
          :withActions="'6%'"
          :buttonEdit="true"
          :buttonDelete="true"
          :myButtons="myButtons"
          @mtd_add_placa="mtd_add_placa"
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :size="'md'"
      :title="modal.title"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
    >
      <CRow>
        <CCol lg="12" md="12" sm="12" xs="12">
          <cSelectForm
            label="Seleccione un documento"
            :options="data_type_document"
            placeholder="Escoja un documento"
            :value.sync="transport.type_document"
          />
        </CCol>
        <CCol lg="12" md="12" sm="12" xs="12">
          <CInput
            label="Documento"
            placeholder="Digite documento"
            v-model="transport.document"
            v-on:keyup.enter="mtd_search_document"
          />
        </CCol>
        <CCol lg="12" md="12" sm="12" xs="12">
          <CInput
            label="Nombre"
            placeholder="Digite nombre de transporte"
            v-model="transport.name"
          />
        </CCol>
        <!-- <CCol lg="4" md="4" sm="12" xs="12">
          <CInput
            label="Placa"
            placeholder="Digite Placa"
            v-model="transport.placa"
          />
        </CCol> -->

        <CCol lg="12" md="12" sm="12" xs="12">
          <cSelectForm
            label="Seleccione un estado"
            :options="data_state"
            placeholder="Escoja un estado"
            :value.sync="transport.state"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      title="Borrar Proveedor"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>

    <!-- modal placa -->
    <CModalForm
      :size="'md'"
      :title="modal_placa.title"
      :show.sync="modal_placa.modal_form"
      @mtd_action="mtd_action_placa"
    >
      <CRow>
        <CCol lg="10" md="10" sm="12" xs="12">
          <CInput
            label=""
            placeholder="Digite nueva placa"
            v-model="modal_placa.new_placa"
          />
        </CCol>
        <CCol lg="2" md="2" sm="12" xs="12">
          <label for="">&nbsp;</label>
          <CButton
            color="info"
            v-c-tooltip.hover="{
              content: 'Agregar placa',
            }"
            @click="mtd_action_placa"
          >
            <i class="fas fa-save"></i>
          </CButton>
        </CCol>
        <CCol lg="12" md="12" sm="12" xs="12">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Id</th>
                <th>Placa</th>
                <th>Borrar</th>
              </tr>
            </thead>
            <tbody v-if="this.modal_placa.data.length > 0">
              <tr v-for="(item, index) in modal_placa.data" :key="index">
                <td width="5%">{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td width="5%">
                  <CButton
                    color="danger"
                    v-c-tooltip.hover="{
                      content: 'Eliminar placa',
                    }"
                    @click="mtd_delete_placa(item.id)"
                  >
                    <i class="fas fa-trash"></i>
                  </CButton>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="text-center">
                <td colspan="3">ESTE TRASPORTE NO TIENE PLACAS</td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
    </CModalForm>
  </div>
</template>


<script>
const fields = [
  { key: "Id", _style: "width:3%" },
  { key: "type_document", label: "Tipo de Documento", _style: "width:10%;" },
  { key: "document", label: "Documento", _style: "width:10%;" },
  // { key: "placa", label: "Placa", _style: "width:20%;" },
  { key: "name", label: "Nombre", _style: "width:20%;" },
  { key: "status", label: "Estado", _style: "width:5%;" },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: { CTableWrapper, CModalForm, cModalDelete, cSelectForm, vSelect },
  data() {
    return {
      prefix: "transports",
      fields,
      data: [],
      data_status_active: "",
      data_status_inactive: "",
      data_type_document: [
        { value: "DNI", label: "DNI" },
        { value: "PASAPORTE", label: "PASAPORTE" },
        { value: "OTRO", label: "OTRO" },
      ],
      data_state: [
        { value: 1, label: "Activo" },
        { value: 2, label: "Inactivo" },
      ],
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Agregar placa",
          action: "mtd_add_placa",
          icon: "fas fa-plus",
        },
      ],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      boo_novo: true,
      transport: {
        id: "",
        type_document: "",
        document: "",
        name: "",
        // placa: "",
        state: "",
      },
      modal_delete: {
        boo: false,
        item: [],
      },
      /** placa */
      modal_placa: {
        modal_form: false,
        transport_id:"",
        title: "",
        new_placa: "",
        data: "",
      },
    };
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.map((item, order) => {
            return { ...item, order };
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo transporte")
        : (this.modal.title = "Editar transporte");
      action == "store"
        ? (this.transport = {
            id: "",
            document: "",
            type_document: "",
            name: "",
            // placa: "",
            state: "",
          })
        : (this.transport = this.transport);
      if (action != "store") {
      }
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.transport,
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (this.modal.action == "store") {
            if (response.state == 0) {
              if (this.data.length == 0) {
                this.mtd_getdata();
              } else {
                this.data.push(response.data[0]);
              }
              message = "REGISTRADO CORRECTAMENTE";
            } else {
              color = "danger";
              message = "EL DOCUMENTO INGRESADO YA EXISTE";
            }
          } else {
            this.data.forEach((element) => {
              if (element.Id == response[0].Id) {
                element.name = response[0].name;
                element.type_document = response[0].type_document;
                // element.placa = response[0].placa;
                element.document = response[0].document;
                element.status = response[0].status;
              }
              message = "EDITADO CORRECTAMENTE";
            });
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            name: "",
            description: "",
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.transport = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.Id != response) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    /** placa */
    mtd_add_placa: function (item) {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/getplaca/" +
          item.Id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.modal_placa.transport_id = item.Id;
          this.modal_placa.modal_form = true;
          this.modal_placa.title = "MODIFICAR PLACAS";
          this.modal_placa.new_placa = "";
          this.modal_placa.data = response;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_action_placa: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/storeplaca",
        token: this.$store.getters.get__token,
        params: this.modal_placa,
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (response.state == 0) {
            message = "REGISTRADO CORRECTAMENTE";
            this.modal_placa.data.push(response.placa);
          } else {
            color = "danger";
            message = response.message;
          }
          this.modal_placa.new_placa="";
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_delete_placa: function(id){
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/deleteplaca/"+id,
        token: this.$store.getters.get__token
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (response.state == 0) {
            message = "ELIMINADO CORRECTAMENTE";
            let temp = [];
            this.modal_placa.data.forEach(element => {
              if (element.id != id) {
                temp.push(element);
              }
            });
            this.modal_placa.data = temp;
          } else {
            color = "danger";
            message = "ERROR AL ELIMINAR";
          }
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
</style>